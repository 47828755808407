/**
 *
 * Region Specific CSS - style
 * 
 */

/**
 * Global Navigation
 * & Footer Menu
 */
.global-nav-ul,
.footer-ul
	> li > a:hover
		color $gray
	&.tohoku > li > a:hover
		color $green
	&.tokai > li > a:hover
		color $blue
	&.kansai > li > a:hover
		color $red
		
/**
 * Pickup
 */
.pickup-ttl
	&.tohoku > a:hover
		color $green
	&.tokai > a:hover
		color $blue
	&.kansai > a:hover
		color $red
		
/**
 * Interview (Single)
 */
.single-body
	&.tohoku
		h3
			color $green
	&.tokai
		h3
			color $blue
	&.kansai
		h3
			color $red