/**
 *
 * Typography - style
 * 
 */

// Basic
html, body
	color $black
	font-family $gothic
	font-size $p
	line-height $p-lh

body
	font-smoothing antialiased
	text-rendering optimizeLegibility
	-webkit-font-smoothing antialiased

h1, h2, h3, h4, h5, h6, p, ul, li, ol, th, td
	-webkit-font-smoothing antialiased
	font-smoothing antialiased

/**
 * Font Size & Line Height
 */
h1, .h1
	font-size $h1-m
	line-height $h2-lh
h2, .h2
	font-size $h2-m
	line-height 34px
h3, .h3
	font-size $h3-m
	line-height $h4-lh
h4, .h4
	font-size $h4-m
	line-height $h5-lh
h5, .h5
	font-size $h5-m
	line-height $h5-lh
h6, .h6
	font-size $h6-m
	line-height $h6-lh
p, .p
	font-size $p
	line-height $p-lh
small, .small
	font-size $small
	line-height $small-lh
.smaller
	font-size $smaller

// h1, h2, h3, h4, h5, h6
//	line-height $heading-line-height
	
/**
 * Color
 */
.black
	color $black
.gray
	color $gray
.green-yellow
	color $green-yellow
.green
	color $green
.white
	color #fff
	
/**
 * Weight
 */
.extraBold
	font-weight 900
b, strong, .bold
	font-weight 700
.light
	font-weight 100
	
/**
 * Style
 */
em, .em
	font-style italic
	
/**
 * Anchor Link
 */
a
	text-decoration none
	&:hover
		text-decoration none
	&.underline:hover
		text-decoration underline

/**
 * Text Alignment
 */
.left
	text-align left
.center
	text-align center
.right
	text-align right
.justify
	text-align justify
	
/**
 * Font Family
 */
.sans-serif
	font-family $sans-serif
.serif
	font-family $serif
.gothic
	font-family $gothic
.mincho
	font-family $mincho
.opensans
	font-family $opensans

/**
 * Line Height
 */
._1em
	line-height 1em
._2em
	line-height 2em

/**
 * Case
 */
.uppercase
	text-transform uppercase
.lowercase
	text-transform lowercase
	
/**
 * Other
 */
.break-all
	word-break break-all