/**
 *
 * Carousel - style
 *
 * Based on Bootstrap v3.3.6 Carousel: http://getbootstrap.com/javascript/#carousel
 */

// Wrapper for the slide container and indicators
.carousel
	position relative

.carousel-inner
	position relative
	overflow hidden
	width 100%

	> .item
		display none
		position relative
		transition .6s ease-in-out left

		// Account for jankitude on images
		> img,
		> a > img
			display block
			max-width 100%
			height auto
			line-height 1

		// WebKit CSS3 transforms for supported devices
		@media all and (transform-3d), (-webkit-transform-3d)
			-webkit-transition -webkit-transform .6s ease-in-out
			-o-transition -o-transform .6s ease-in-out
			transition transform .6s ease-in-out
			backface-visibility hidden
			perspective 1000px

			&.next,
			&.active.right
				transform translate3d(100%, 0, 0)
				left 0

			&.prev,
			&.active.left
				transform translate3d(-100%, 0, 0)
				left 0

			&.next.left,
			&.prev.right,
			&.active
				transform translate3d(0, 0, 0)
				left 0

	> .active,
	> .next,
	> .prev
		display block
	
	> .active
		left 0

	> .next,
	> .prev
		position absolute
		top 0
		width 100%

	> .next
		left 100%

	> .prev
		left -100%
 
	> .next.left,
	> .prev.right
		left 0

	> .active.left
		left -100%

	> .active.right
		left 100%

// Left/right controls for nav
// ---------------------------

.carousel-control
	position absolute
	top 0
	bottom 0
	left 0
	width 15%
	font-size 20px
	color #fff
	text-align center
	text-shadow 0 1px 2px rgba(0, 0, 0, .6)
	background-color rgba(0, 0, 0, 0)
	opacity .5

	// Set gradients for backgrounds
	&.left
		background-image -webkit-linear-gradient(left, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%)
		background-image -o-linear-gradient(left, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%)
		background-image -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .5)), to(rgba(0, 0, 0, .0001)))
		background-image linear-gradient(to right, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, .0001) 100%)
		background-repeat repeat-x

	&.right
		left auto
		right 0
		background-image -webkit-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%)
		background-image -o-linear-gradient(left, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%)
		background-image -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, .0001)), to(rgba(0, 0, 0, .5)))
		background-image linear-gradient(to right, rgba(0, 0, 0, .0001) 0%, rgba(0, 0, 0, .5) 100%)
		background-repeat repeat-x


	// Hover/focus state
	&:hover,
	&:focus
		color #fff
		text-decoration none
		outline 0
		opacity .9

	// Toggles
	.icon-prev,
	.icon-next,
	.glyphicon-chevron-left,
	.glyphicon-chevron-right
		position absolute
		top 50%
		margin-top -10px
		z-index 5
		display inline-block

	.icon-prev,
	.glyphicon-chevron-left
		left 50%
		margin-left -10px

	.icon-next,
	.glyphicon-chevron-right
		right 50%
		margin-right -10px

	.icon-prev,
	.icon-next
		width  20px
		height 20px
		line-height 1
		font-family serif

	.icon-prev
		&:before
			content '\2039'// SINGLE LEFT-POINTING ANGLE QUOTATION MARK (U+2039)

	.icon-next
		&:before
			content '\203a'// SINGLE RIGHT-POINTING ANGLE QUOTATION MARK (U+203A)

// Optional indicator pips
//
// Add an unordered list with the following class and add a list item for each
// slide your carousel holds.

.carousel-indicators
	position absolute
	bottom 10px
	left 50%
	z-index 15
	width 60%
	margin-left -30%
	padding-left 0
	list-style none
	text-align center

	li
		display inline-block
		width  10px
		height 10px
		margin 1px
		text-indent -999px
		border 1px solid #fff
		border-radius 10px
		cursor pointer

		// IE8-9 hack for event handling
		//
		// Internet Explorer 8-9 does not support clicks on elements without a set
		// `background-color`. We cannot use `filter` since that's not viewed as a
		// background color by the browser. Thus, a hack is needed.
		// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Internet_Explorer
		//
		// For IE8, we set solid black as it doesn't support `rgba()`. For IE9, we
		// set alpha transparency for the best results possible.
		background-color #000 \9 // IE8
		background-color rgba(0,0,0,0) // IE9

	.active
		margin 0
		width  12px
		height 12px
		background-color #fff


// Optional captions
// -----------------------------
// Hidden by default for smaller viewports
.carousel-caption
	position absolute
	left 15%
	right 15%
	bottom 20px
	z-index 10
	padding-top 20px
	padding-bottom 20px
	color #fff
	text-align center
	text-shadow 0 1px 2px rgba(0, 0, 0, .6)
	& .btn
		text-shadow: none // No shadow for button elements in carousel-caption