/**
 *
 * Header - style
 * 
 */

#header
	background-color #fff

.header-message
	font-size 10px
	line-height 15px
	padding 8px 0 0
	text-indent -3px

.header-inner
	border-bottom 1px solid $gray
	overflow-y hidden
	max-height 46px
	padding 10px 0 8px
	position relative
	transition max-height .5s ease-in-out
	&.opened
		max-height 1000px

.logo
	width 204px
	height 22px
	margin-top 2px
.logo-img
	display block
	width auto
	height 100%
	transition opacity .3s
	&:hover
		opacity .5

// Button
#navToggleBtn
	cursor pointer
	position absolute
	right 0
	top 14px
	width 20px
	-webkit-tap-highlight-color transparent
	> span
		background-color $black
		display block
		height 1px
		margin 5px 0
		position relative

// Toggle Open
.opened #navToggleBtn span
	&:first-child
		top 5px
		transform rotate(45deg)
	&:last-child
		top -1px
		transform rotate(-45deg)
	&:nth-child(2)
		display none

// Global Navigation
.global-nav
	margin-top 12px
.global-nav-ul
	@extend .flex
	justify-content space-between
	> li
		padding 6px 0
		text-align center
		width 50%
		> a
			display block
			line-height 20px
			transition color .3s
	

/* .header-spacer
	height 48px */