/*
Theme Name: MINI
Author: Zac Fukuda
Author URI: http://www.zacfukuda.com/
Description: Minimal Template for Wordpress Theme Development
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: 
Text Domain: mini
*/

@charset "UTF-8"
@import "common/variables"
@import "common/mixins"
@import "common/reset"

@import "base/"
@import "typography/"

// Modules
@import "module/button/"
@import "module/card/"
@import "module/carousel/"
@import "module/form/"
@import "module/grid/"
@import "module/list/"
@import "module/table/"

// Layout
@import "layout/header/"
@import "layout/sidebar/"
@import "layout/footer/"

// Specific CSS for each page
@import "page/all/"
@import "page/index/"
@import "page/post/"
@import "page/region-specific"