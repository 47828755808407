/**
 *
 * Footer - style
 * 
 */

// Contact
.to-contact
	border-top 1px solid $gray
	margin-top 48px
	padding-top 32px
	> p > br
		display none

.to-contact-btn
	border 1px solid $green
	border-radius 4px
	color $green
	display block
	font-size $h5
	line-height 1
	margin-top 20px
	max-width 352px
	padding 13px 16px 12px
	transition color .3s, background-color .3s
	&:hover
		background-color $green
		color #fff

// Footer
#footer
	margin-top 64px
	padding-bottom 4px

.footer-inner
	border-top 1px solid $gray
	padding 24px 0 0

// To Top
#toTop
	width 33px
	height 25px
	position absolute
	border 1px solid $gray
	border-bottom-color #fff
	content ""
	display block
	padding 11px 11px
	right 16px
	top -25px
	z-index 10
	> span
		width 10px
		height 10px
		border-top 2px solid #9f9fa0
		border-left 2px solid #9f9fa0
		content ""
		display block
		position relative
		top 0
		transform rotate(45deg)
		transition top .1s linear
	&:hover > span
		top -3px

// Logo
.footer-logo-a
	display block
	width 204px
	height 22px
.footer-logo-img
	width auto
	height 100%
	transition opacity .3s
	&:hover
		opacity .5

// Navigation
.footer-nav
	margin-top 24px
.footer-ul
	@extend .flex
	> li
		margin-bottom 8px
		width 120px

// Copyright
.copyright
	color #717071
	margin-top 8px