/**
 *
 * Post - style
 * 
 */
 
/**
 * index & archive
 */
.index-article-a:hover
	.index-article-pic > img
		opacity .7
		transform scale(1.08, 1.08)

.index-article-pic
	display block
	margin-bottom 14px
	overflow hidden
	> img
		transition transform .3s ease-in-out, opacity .3s ease-in-out
		width 100%
		
div.empty-thumb
	background-color #f0f0f0
	margin-bottom 14px
	padding-bottom 66.666666%
	width 100%

.index-article-ttl
	font-family $mincho
	margin-bottom 8px
	> span
		display block
.toInteview
	@extend .hasBefore
	display inline-block
	transition color .3s
	&:before
		background-color $gray
		left 0
		bottom -2px
		width 100%
		height 1px
		transition background-color .3s
	&:hover
		color $green
		&:before
			background-color $green
			
/**
 * Single
 */
article.single
	margin-top 16px
	
.single-body,
.company-info,
.single-footer
	max-width 640px
	margin-left auto
	margin-right auto

// Header
.single-header
	border-bottom 1px solid $gray
	padding-bottom 16px
	h1
		margin-bottom 6px

.single-header-company
	display block
	
// Body
.single-body
	margin-top 24px
	h3
		// color $green
		font-family $mincho
		font-size $h4-m
		font-weight bold
		line-height $h4-lh
		margin 32px 0 24px
	p, ul, ol
		font-size $h6
		line-height 24px
		margin-top 24px
	img
		margin-top 8px
		&.aligncenter
			display block
	ul, ol
		margin-left 1.5em
	ul
		list-style disc
	ol
		list-style decimal
		li
			padding-left .5em
	a
		color $green
		&:hover
			text-decoration underline

// Company
.company-info
	margin-top 40px
	> h2
		border-top 1px solid $gray
		border-bottom 1px solid $gray
		line-height 1
		padding 16px 0

.company-info-table
	margin-top 24px
	width 100%
	th, td
		padding 3px 0
		vertical-align baseline
	th
		width 65px
	td
		padding-left 32px
		
// Footer
.single-footer
	margin-top 32px
.sharing-wrap
	margin-left -4px
.addtoany_list
	display block !important
	height 28px
	margin-left -4px