/**
 *
 * Front Page - style
 * 
 */

/**
 * Pickup
 */
.pickup-inner
	border-bottom 1px solid $gray
	margin-top 24px
	padding-bottom 24px
	text-align center

.pickup-pic,
.pickup-texts
	width 100%
	padding 0 16px

.pickup-pic-a
	display block
	margin 0 -16px
	overflow hidden
	> img
		transition transform .3s ease-in-out, opacity .3s ease-in-out
		width 100%
		&:hover
			opacity .7
			transform scale(1.05, 1.05)

.pickup-h3
	background-color #fff
	margin -24px auto 24px
	padding 16px 16px 24px
	width 80%
	max-width 256px
	&:after
		background-color $gray
		left 50%
		bottom 0
		width 96px
		height 1px
		margin-left -48px

.pickup-ttl
	margin 0 0 24px 0
	> a
		> br
			display none

.pickup-name
	display inline-block
	font-weight normal
	
// Index part is in post