/**
 *
 * CSS throughout All Pages - style
 * 
 */
 
main
	&.index,
	&.page
		margin 0 0 48px

// Archive & Page Title
.archive-ttl,
.page-ttl
	@extend .mincho
	border-bottom 1px solid $gray
	font-size $h2-m
	line-height 34px
	padding 0 0 11px

.archive-ttl
	margin 13px 0 0
	
.page-ttl
	margin 13px 0 32px

.row-flex.form
	.flex-6,
	.flex-12
		margin-top 16px
		
// Pagination
.pagination
	margin-top 32px
	text-align center

.page-numbers
	display inline-block
	font-size $h6
	margin 0 5px
	&.prev
		margin-right 8px
	&.next
		margin-left 8px
	&.current
		@extend .hasBefore
		color #b4b5b5
		top 6px
		&:before
			width 4px
			height 4px
			border-radius 2px
			background-color #b4b5b5
			top -4px
			left 2px
a.page-numbers:hover
	color $gray