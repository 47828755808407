/**
 *
 * Grid - style
 * 
 */
 
.container
	max-width $container-width // 640px
	margin-left auto
	margin-right auto
	padding-left ($gutter/2)
	padding-right ($gutter/2)

.row
	@extend .clearfix
	margin-right -($gutter)
	margin-left -($gutter)

.flex
	flex()
	flex-wrap wrap

.row-flex
	@extend .flex
	margin-right -($gutter/2)
	margin-left -($gutter/2)

.flex,
.row-flex
	&.nowrap
		flex-wrap nowrap
	&.align-center
		align-items center
	&.justify-center
		justify-content center
	&.space-between
		justify-content space-between
	&.space-around
		justify-content space-around
	
[class^="col-"],
[class^="flex-"]
	margin-top $gutter
	padding-right ($gutter/2)
	padding-left ($gutter/2)
	&.no-padding
		padding-right 0
		padding-left 0

[class^="flex-"]
	width 100%

.col-2, .col-1,
.flex-2, .flex-1
	width 50%

.col-2, .col-1
	float left