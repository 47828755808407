/**
 *
 * Form Elements - style
 * 
 */

form
	margin-top 16px
	p
		margin-top 16px

label
	font-size $h6
	line-height $h6-lh
	
input,
textarea
	border 1px solid #c9c9ca
	font-size $h6
	line-height $h6-lh
	
input
		padding 0 4px
	
textarea
	padding 4px 8px
	
input[type="text"],
input[type="email"],
input[type="tel"],
textarea
	border-radius 2px
	margin-top 2px
	width 100%

input[type="submit"]
	appearance none
	background-color transparent
	border 1px solid $gray
	border-radius 4px
	color $gray
	cursor pointer
	font-size $h5
	letter-spacing 16px
	line-height 1
	margin-top 16px
	max-width 352px
	padding 13px 16px 12px
	transition color .3s, background-color .3s
	width 100%
	&:hover
		background-color $gray
		color #fff
	&:focus
		outline none

.required
	color #ce0000