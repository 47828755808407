/**
 *
 * Base - style
 * 
 */

// Basic
html,
body
	width 100%
	margin 0
	padding 0
	position relative
	overflow-x hidden

$section-space-m = 32px
section
	&.padding
		padding $section-space-m 0
	&.padding-top
		padding-top $section-space-m
	&.padding-bottom
		padding-bottom $section-space-m
	&.margin
		margin $section-space-m 0
	&.margin-top
		margin-top $section-space-m
	&.margin-bottom
		margin-bottom $section-space-m
		
img
	height auto
	max-width 100%
	vertical-align bottom
	&.w100
		width 100%
		
a.anchor
	display block
	position relative
	top -($header-height+16px)
	visibility hidden
	
// Clearfix
.clearfix
	clearfix()
.clearboth
	clear both
	
// Block Alighment
.aligncenter
	margin-left auto
	margin-right auto

// Display Style
.block
	display block
.ib
	display inline-block
.inline
	display inline
.table
	display table
.table-cell
	display table-cell
	
.tablet,
.desktop
	display none
	
// position
.relative
	position relative
.absolute
	position absolute
.fixed
	position fixed
	
// Before & After
.hasBeforeAfter
	position relative
	&:before, &:after
		content ""
		display block
		position absolute
.hasBefore
	position relative
	&:before
		content ""
		display block
		position absolute
.hasAfter
	position relative
	&:after
		content ""
		display block
		position absolute
		
// Transition
.trans-opacity
	transition opacity .5s
	&:hover
		opacity .6
		text-decoration none
		
.trans-bg
	transition background .5s
	&:hover
		text-decoration none

.trans-bg-border
	transition background .5s, border .5s
	&:hover
		text-decoration none